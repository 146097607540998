export default [
    {
        path: '/product/list',
        name: 'productList',
        component: () => import('../view/list'),
        meta: {
            title: '产品列表',
            keepAlive: true,
            auth: false,
            fullScreen: false,
        }
    },
    {
        path: '/product/detail',
        name: 'productDetail',
        component: () => import('../view/detail'),
        meta: {
            title: '产品',
            keepAlive: false,
            auth: false,
            fullScreen: true,
        }
    }
]

