import { render, staticRenderFns } from "./payTips.vue?vue&type=template&id=688a1502&scoped=true"
import script from "./payTips.vue?vue&type=script&lang=js"
export * from "./payTips.vue?vue&type=script&lang=js"
import style0 from "./payTips.vue?vue&type=style&index=0&id=688a1502&prod&lang=scss"
import style1 from "./payTips.vue?vue&type=style&index=1&id=688a1502&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688a1502",
  null
  
)

export default component.exports