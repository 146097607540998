import SkeletonLoading from './SkeletonLoading.vue';
import SkeletonHome from './SkeletonHome.vue';
import SkeletonUser from './SkeletonUser.vue';
import SkeletonProduct from './SkeletonProduct.vue';
import SkeletonProductList from './SkeletonProductList.vue';
import SkeletonProductDetail from './SkeletonProductDetail.vue';
import SkeletonPlan from './SkeletonPlan.vue';
import SkeletonOrder from './SkeletonOrder.vue';

function install (Vue) {
    if (install.installed) return;
    install.installed = true;
    Vue.component('SkeletonLoading', SkeletonLoading);
    Vue.component('SkeletonHome', SkeletonHome);
    Vue.component('SkeletonUser', SkeletonUser);
    Vue.component('SkeletonProduct', SkeletonProduct);
    Vue.component('SkeletonProductList', SkeletonProductList);
    Vue.component('SkeletonProductDetail', SkeletonProductDetail);
    Vue.component('SkeletonPlan', SkeletonPlan);
    Vue.component('SkeletonOrder', SkeletonOrder);
}

const VueSkeletonLoading = {
    install,
    SkeletonLoading,
    SkeletonHome,
    SkeletonUser,
    SkeletonProduct,
    SkeletonProductList,
    SkeletonProductDetail,
    SkeletonPlan,
    SkeletonOrder,
};

if (typeof window !== undefined && window.Vue) {
    window.Vue.use(SkeletonLoading);
}


export default VueSkeletonLoading;

