export default [
    {
        path: '/login',
        name: 'login',
        component: () => import('../view/login/index'),
        meta: {
            title: '登录',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            hideFooter: true
        }
    },
    {
        path: '/loginByPhone',
        name: 'login',
        component: () => import('../view/login/loginByPhone'),
        meta: {
            title: '登录',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false,
            hideFooter: true
        }
    },

    {
        path: '/wx_login',
        name: 'wxLogin',
        component: () => import('../view/weixin/wx_login'),
        meta: {
            title: '登录',
            keepAlive: false,
            fullScreen: true,
            weixinAuth: false
        }
    },

    {
        path: '/user/index',
        name: 'userIndex',
        component: () => import('../view/index'),
        meta: {
            title: '用户中心',
            keepAlive: false,
            auth: true
        }
    },
    {
        path: '/user/profile',
        name: 'userProfile',
        component: () => import('../view/user/profile'),
        meta: {
            title: '个人信息',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },

    {
        path: '/user/settle/pay',
        name: 'payBatchList',
        component: () => import('../view/settle/payBatchList'),
        meta: {
            title: '保费支付',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/user/settle/commission',
        name: 'JGCommissonBatchList',
        component: () => import('../view/settle/JGCommissonBatchList'),
        meta: {
            title: '佣金结算',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/user/settle/order',
        name: 'orderList',
        component: () => import('../view/settle/orderList'),
        meta: {
            title: '结算订单',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/user/settle/orderUnpay',
        name: 'orderList',
        component: () => import('../view/settle/orderUnpayList'),
        meta: {
            title: '待支付订单',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },

    {
        path: '/user/balance',
        name: 'accountBalance',
        component: () => import('../view/account/balance'),
        meta: {
            title: '我的账户',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },
    {
        path: '/user/account/bill',
        name: 'accountBill',
        component: () => import('../view/account/bill'),
        meta: {
            title: '账户流水',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },

    {
        path: '/coop',
        name: 'coop',
        component: () => import('../view/coop'),
        meta: {
            title: '合作案例',
            auth: false,
            keepAlive: true,
            fullScreen: true,
        },
    },

    {
        path: '/about',
        name: 'about',
        component: () => import('../view/about'),
        meta: {
            title: '关于我们',
            auth: false,
            keepAlive: true,
            fullScreen: true,
        },
    },
    {
        path: '/claimsProcess',
        name: 'claimsProcess',
        component: () => import('../view/claimsProcess'),
        meta: {
            title: '理赔流程',
            keepAlive: false,
            fullScreen: true,
            auth: true
        }
    },

]
