import router from '@/core/router';
import { pinyin } from "./pinyin";
import {filterHtml, formatMoneyNoZero} from "../filters";
import moment from 'moment'

export class BTUtil {
    //汉字转拼音，获取首字母
    static chineseToPinYin (l1) {
        var l2 = l1.length;
        var I1 = '';
        var reg = new RegExp('[a-zA-Z0-9]');
        for (var i = 0; i < l2; i++) {
            var val = l1.substr(i, 1);
            var name = this.arraySearch(val, pinyin);
            if (reg.test(val)) {
                I1 += val
            } else if (name !== false) {
                I1 += name
            }
        }
        I1 = I1.replace(/ /g, '-');
        while (I1.indexOf('--') > 0) {
            I1 = I1.replace('--', '-')
        }
        return I1[0]
    };

    static arraySearch (l1) {
        for (var name in pinyin) {
            if (pinyin[name].indexOf(l1) !== -1) {
                return this.ucfirst(name)
            }
        }
        return false
    };

    static ucfirst (l1) {
        if (l1.length > 0) {
            var first = l1.substr(0, 1).toUpperCase();
            var spare = l1.substr(1, l1.length);
            return first + spare
        }
    }

    static isEmpty (val) {
        if (val instanceof Array) {
            if (val.length == 0) return true;
        } else if (val instanceof Object) {
            if (JSON.stringify(val) === '{}') return true;
        } else {
            if (val == 'null' || val == null || val == 'undefined' || val == undefined || val == '') return true;
            return false;
        }
        return false;
    }

    static isWeixin () {
        //const ua = navigator.userAgent.toLowerCase();
        if (navigator.userAgent.match(/MicroMessenger/i)) {
            return true;
        } else {
            return false;
        }
    }

    static isIPhone () {
        //var ua = navigator.userAgent.toLowerCase();
        if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    static isAndroid () {
        if (/(Android)/i.test(navigator.userAgent)) {
            return true;
        } else {
            return false;
        }
    }

    static isMobile () {
        return !/macintosh|window/.test(navigator.userAgent.toLowerCase());
    }

    /***手机版微信版客户端
     *
     * @returns {boolean}
     */
    static isMobileWeixin () {
        if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
            if (this.isWeixin()) {
                return true;
            } else {
                return false;
            }
        } else {
            return false;
        }
    }

    /***区别与显示 用于库存储
     *
     * @param picUrl
     * @param size
     * @returns {*}
     */
    //获取不同尺寸图片 small 160*160 middle  320宽  large 640宽
    static convertPicUrl (picUrl, size = '') {
        if (picUrl && picUrl.indexOf("http") == -1) {
            if (size) {
                return "http://" + picUrl + "_" + size;
            } else {
                return "http://" + picUrl;
            }
        } else {
            if (size) {
                return picUrl + "_" + size;
            } else {
                return picUrl;
            }
        }
    }

    /***是否在资源域名下
     *
     * @param picUrl
     * @returns {boolean}
     */
    static inFileHost (picUrl) {
        const fileHosts = ['file.tyyunbao.com.cn', 'tengyi-cloud.oss-cn-shanghai.aliyuncs.com'];
        fileHosts;
        for (let host of fileHosts) {
            if (picUrl.indexOf(host) != -1) {
                return true;
            }
        }
        return false;
    }

    /***支持http和https图片显示
     *
     * @param picUrl
     * @param size
     * @returns {*}
     */
    static showPicUrl (picUrl, size = '') {
        if (picUrl && BTUtil.inFileHost(picUrl)) {
            picUrl = picUrl.replace("http://", "");
            picUrl = picUrl.replace("https://", "");
            if (size) {
                return "//" + picUrl + "_" + size;
            } else {
                return "//" + picUrl;
            }
        } else {
            if (size) {
                return picUrl + "_" + size;
            } else {
                return picUrl;
            }
        }
    }

    static filterProtocol (picUrl) {
        if (picUrl && picUrl.indexOf("http:") != -1) {
            picUrl = picUrl.replace("http:", "");
        } else if (picUrl && picUrl.indexOf("https:") != -1) {
            picUrl = picUrl.replace("https:", "");
        }
        return picUrl;
    }

    static getPicUrl (picUrl) {
        if (picUrl && picUrl.startsWith("http:")) {
            picUrl = picUrl.replace("http:", "https:");
        }
        return picUrl;
    }

    //获取二维码图片
    static convertQrcode (qrcodeText, size = '') {
        return "/qrcode?text=" + this.base64Encode(qrcodeText) + "&size=" + size;
    }

    //获取在线文档预览
    static convertDocUrl (docTitle, docUrl, type = 'pdf') {
        //return docUrl;
        if (docUrl.indexOf(".htm") != -1) {
            return docUrl;
        }
        if (docUrl.indexOf("http://file.") != -1) {
            docUrl = docUrl.replace("http://file.", "https://file.");
        }
        // const previewUrl = process.env.VUE_APP_DOMAIN + "/sp/doc?url=" + encodeURIComponent(docUrl) + "&title=" + encodeURIComponent(docTitle) + "&type=" + type;
        const previewUrl = process.env.VUE_APP_FILE_DOMAIN + encodeURIComponent(docUrl) + "&title=" + encodeURIComponent(docTitle) + "&type=" + type;
        // console.log("previewUrl:",previewUrl);
        return previewUrl;
    }

    //预览
    static previewHtml (title, img) {
        const previewUrl = "/page/preview.html?img=" + encodeURIComponent(img) + "&title=" + encodeURIComponent(title);
        document.location.href = previewUrl;
    }

    /***跳转连接
     *
     * @param url
     */
    static jumpLink (url) {
        if (url.indexOf('html') == -1) {
            if (url.indexOf('http') == -1) {
                router.push(url);
            } else {
                document.location.href = url;
            }
        } else {
            document.location.href = url;
        }
    }

    /***获取路由后面的参数
     *
     * @param name
     * @returns {*}
     * @constructor
     */
    static GetQueryString (name) {
        var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)");
        var r = window.location.search.substr(1).match(reg);//search,查询？后面的参数，并匹配正则
        if (r != null) return r[2];
        return null;
    }

    static base64Encode (str) {
        let base64Str = Base64.encode(str);
        base64Str = base64Str.replace(/\+/g, "%2B");
        return base64Str;
    }

    static base64Decode (str) {
        return Base64.decode(str);
    }

    static dateToStr (d, fmt) {
        var o = {
            "M+": d.getMonth() + 1, //月份
            "d+": d.getDate(), //日
            "h+": d.getHours(), //小时
            "m+": d.getMinutes(), //分
            "s+": d.getSeconds(), //秒
            "q+": Math.floor((d.getMonth() + 3) / 3), //季度
            "S": d.getMilliseconds() //毫秒
        };
        if (/(y+)/.test(fmt)) {
            fmt = fmt.replace(RegExp.$1, (d.getFullYear() + "").substr(4 - RegExp.$1.length));
        }
        for (var k in o) {
            if (new RegExp("(" + k + ")").test(fmt)) {
                fmt = fmt.replace(RegExp.$1, (RegExp.$1.length == 1) ? (o[k]) : (("00" + o[k]).substr(("" + o[k]).length)));
            }
        }
        return fmt;
    }

    static stringToDate (str) {
        return new Date(str.replace(/-/g, '/'));
    }

    static dateAddDay (d, day) {
        let date = new Date(d);
        date.setDate(date.getDate() + day); //设置天数 -1 天
        return date;
    }

    static dateAddMonth (d, month) {
        let date = new Date(d);
        date.setDate(1);
        date.setMonth(date.getMonth() + month); //设置月份

        return date;
    }

    static dateAddMonth2 (d, month) {
        let date = new Date(d);
        date.setMonth(date.getMonth() + month); //设置月份

        return date;
    }

    //下个月的今天
    static getNextMonth (date) {
        var arr = date.split('-');
        var year = arr[0]; //获取当前日期的年份  
        var month = arr[1]; //获取当前日期的月份  
        var day = arr[2]; //获取当前日期的日  
        var days = new Date(year, month, 0);
        days = days.getDate(); //获取当前日期中的月的天数  
        var year2 = year;
        var month2 = parseInt(month) + 1;
        if (month2 == 13) {
            year2 = parseInt(year2) + 1;
            month2 = 1;
        }
        var day2 = day;
        var days2 = new Date(year2, month2, 0);
        days2 = days2.getDate();
        if (day2 > days2) {
            day2 = days2;
        }
        if (month2 < 10) {
            month2 = '0' + month2;
        }

        var t2 = year2 + '-' + month2 + '-' + day2;
        return t2;
    }

    //一年后
    static oneYearPast (time) {
        var year = time.getFullYear() + 1;
        var month = time.getMonth() + 1;
        var day = time.getDate();
        if (month < 10) {
            month = "0" + month;
        }

        if (day > 1) {
            day = day - 1;
        } else {
            month = month - 1;
            if (month < 10) {
                month = "0" + month;
            }
            if (month == 0) {
                month = 12;
            }
            day = new Date(year, month, 0).getDate();
        }
        if (day < 10) {
            day = '0' + day;
        }
        var v2 = year + '-' + month + '-' + day;
        return v2;
    }

    static dateAddHour (d, hour) {
        let date = new Date(d.valueOf() + hour * 60 * 60 * 1000); //加减小时
        return date;
    }

    static combine (dst, src, overwrite = true, newInstance = true) {
        if (!src) return dst;
        let body = dst ? dst : {};
        if (newInstance) {
            body = dst ? JSON.parse(JSON.stringify(dst)) : {};
        }

        Object.keys(src).forEach(function (key) {
            if (src[key] !== null && src[key] !== '') {
                if (src[key] != body[key] || overwrite) {
                    if (src[key] instanceof Object && body[key] instanceof Object) {
                        let obj = BTUtil.combine(body[key], src[key]);
                        body[key] = obj;
                    } else {
                        body[key] = src[key];
                    }
                }
            }
        });
        return body;
    }

    static assignProperty (obj, path, value) {
        let levels = path.split('.');
        let cur = obj;
        for (let i = 0; i < levels.length; i++) {
            let key = levels[i];
            if (i == levels.length - 1) {
                cur[key] = value;
            } else {
                if (!cur[key])
                    cur[key] = {};
                cur = cur[key];
            }
        }
    }

    static formattedAmount (amount) {
        if (amount === null || amount === undefined || amount == -1) return '不投保';
        if (amount < 10000) return (amount / 1000.0) + '千';
        return BTUtil.getDivAccuracyNumber(amount, 10000) + '万';
    }

    static getDivAccuracyNumber (orig, deno) {
        let a = BTUtil.getAccuracyNumber(orig);
        return a[0] / (deno * a[1]);
    }

    static getAccuracyNumber (orig) {
        let s = orig.toString();
        let i = s.indexOf(".");
        if (i > 0 && i < (s.length - 1)) {
            var v = Math.pow(10, s.substr(i + 1).length);
            return [orig * v, v];
        } else {
            return [orig, 1];
        }
    }

    static checkString (str, len = 0) {
        if (!str) return false;
        if (str.length == 0) return false;
        if (len != 0 && str.length != len) return false;

        return true;
    }

    static cutDateToDay (date) {
        if (!date)
            return null;

        if (date.length > 10)
            return date.substr(0, 10);

        return date;
    }

    static fillFullDate (date) {
        if (!date)
            return null;

        if (date.length == 10)
            return date + ' 00:00:00';

        if (date.length == 16)
            return date + ':00';

        return date;
    }

    static indexOfObject (array, prop, value) {
        for (let i = 0; i < array.length; i++) {
            let tmp = array[i];
            if (tmp[prop] == value)
                return i;
        }

        return -1;
    }

    /*** 转换字符串成数组
     *
     * @param str
     * @returns {*[]}
     */
    static convertStrToArray (str) {
        let strs;
        if (!str) {
            return [];
        }
        if (str instanceof Array) {
            return str;
        }
        if (typeof str != 'string') {
            throw 'convertStrToArray';
        }
        if (str.search(/,/ig) > -1) {
            strs = str.split(',')
        } else if (str.search(/，/ig) > -1) {
            strs = str.split('，')
        } else if (str.search(/\r\n/ig) > -1) {
            strs = str.split('\r\n')
        } else if (str.search(/\n/ig) > -1) {
            strs = str.split('\n')
        } else {
            strs = [str]
        }
        return strs
    }

    /***按属性分组数据
     *
     * @param arr
     */
    static arrayGroupByKey (arr, key) {
        var newArr = [],
            types = {},
            newItem, i, j, cur;
        for (i = 0, j = arr.length; i < j; i++) {
            cur = arr[i];
            if (!(cur[key] in types)) {
                types[cur[key]] = {key: 'group_' + j, title: cur[key], data: []};
                newArr.push(types[cur[key]]);
            }
            types[cur[key]].data.push(cur);
        }
        console.log(newArr);
        return newArr;
    }

    static getJsonLength (jsonData) {
        let length = 0;
        for (let ever in jsonData) {
            length++;
        }
        return length;
    }

    static getUrlParame (parameName) {
        const parames = window.location.search;
        if (parames.indexOf(parameName) > -1) {
            let parameValue = '';
            parameValue = parames.substring(parames.indexOf(parameName), parames.length);
            if (parameValue.indexOf('&') > -1) {
                parameValue = parameValue.substring(0, parameValue.indexOf('&'));
                parameValue = parameValue.replace(parameName + '=', '');
                return parameValue
            }
            return ''
        }
    }

    //检测是否车牌号
    static isVehicleNo (str) {
        return /^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[ADF])|([ADF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))$/.test(str);
    }

    /***提取车牌号
     *
     * @param text
     * @returns {string}
     */
    static getVehicleNo (text) {
        const regex = /(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z](([0-9]{5}[ADF])|([ADF]([A-HJ-NP-Z0-9])[0-9]{4})))|([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领][A-Z][A-HJ-NP-Z0-9]{4}[A-HJ-NP-Z0-9挂学警港澳使领]))/i;
        const txt = text.toUpperCase().match(regex);
        if (txt) {
            return txt[1];
        } else {
            return text.toUpperCase();
        }
    }

    /**
     * 提取所有城市
     */
    static extractCityList = (cityList) => {
        const allCityList = [];
        for (let province of cityList) {
            for (let city of province.cities) {
                allCityList.push(city);
            }
        }
        return allCityList;
    };
    /**
     * 通过城市ID在城市列表中查找城市ID
     */
    static findCityIdByCityList = (cityList, cityName) => {
        for (let province of cityList) {
            for (let city of province.cities) {
                if (city.name == cityName) {
                    return city.id;
                }
            }
        }
        return 0;
    };
    /**
     * 通过城市名称在城市列表中查找城市名称
     */
    static findCityNameByCityList = (cityList, cityId) => {
        for (let province of cityList) {
            for (let city of province.cities) {
                if (city.id == cityId) {
                    return city.name;
                }
            }
        }
        return 0;
    };
    /**
     * 通过城市名称在城市列表中查找城市车牌
     */
    static findCityPrefixByCityList = (cityList, cityId) => {
        for (let province of cityList) {
            for (let city of province.cities) {
                if (city.id == cityId) {
                    return city.prefix;
                }
            }
        }
        return 0;
    };

    /***格式化字符串
     *
     * @param str    {0}
     * @param data
     * @returns {*}
     */
    static formatString (str, data) {
        if (!str || data == undefined) {
            return str;
        }

        if (typeof data === "object") {
            for (var key in data) {
                if (data.hasOwnProperty(key)) {
                    str = str.replace(new RegExp("\{" + key + "\}", "g"), data[key]);
                }
            }
        } else {
            var args = arguments,
                reg = new RegExp("\{([0-" + (args.length - 1) + "])\}", "g");
            return str.replace(reg, function (match, index) {
                return args[index - (-1)];
            });
        }
        return str;
    }

    /***批量格式化object字符串
     *
     * @param str
     * @param data
     */
    static formatObjString (object, data) {
        let newObject = {};
        for (let _objKey in object) {
            let tempObj = object[_objKey];
            const regContain = new RegExp("\{(\\d)\}", "g");
            if (regContain.test(tempObj)) {
                for (let _key = 0; _key < data.length; _key++) {
                    //console.log("_key:" + _key + ",tempObj:" + tempObj);
                    const regEx = new RegExp("\{([0-" + _key + "])\}", "g");
                    if (regEx.test(tempObj)) {
                        tempObj = tempObj.replace(regEx, data[_key]);
                    }
                }
            }
            newObject[_objKey] = tempObj;
        }
        return newObject;
    }


    static bfConvertUnit (arg) {
        let result = 0;
        if (arg && arg != null && arg != '' && arg > 0) {
            if (arg >= 10000) {
                result = '约' + (arg / 10000.0).toFixed(2) + '万';
            } else {
                result = arg.toFixed(2);
            }
        }
        return result;
    }

    /***提示微信内打开
     *   return this.$util.weixinSupport();
     * @returns {boolean}
     */
    static weixinSupport () {
        var ua = navigator.userAgent.toLowerCase();
        var isWeixin = ua.indexOf('micromessenger') != -1;
        var isAndroid = ua.indexOf('android') != -1;
        var isIos = (ua.indexOf('iphone') != -1) || (ua.indexOf('ipad') != -1);
        if (!isWeixin) {
            document.head.innerHTML = '<title>抱歉，出错了</title><meta charset="utf-8"><meta name="viewport" content="width=device-width, initial-scale=1, user-scalable=0"><link rel="stylesheet" type="text/css" href="https://res.wx.qq.com/open/libs/weui/0.4.1/weui.css">';
            document.body.innerHTML = '<div class="weui_msg"><div class="weui_icon_area"><i class="weui_icon_info weui_icon_msg"></i></div><div class="weui_text_area"><h4 class="weui_msg_title">请在微信客户端打开链接</h4></div></div>';
        }
        return false;
    }

    //身份证号合法性验证
    //支持15位和18位身份证号
    //支持地址编码、出生日期、校验位验证
    static IdentityCodeValid (code) {
        let city = {
            11: "北京",
            12: "天津",
            13: "河北",
            14: "山西",
            15: "内蒙古",
            21: "辽宁",
            22: "吉林",
            23: "黑龙江 ",
            31: "上海",
            32: "江苏",
            33: "浙江",
            34: "安徽",
            35: "福建",
            36: "江西",
            37: "山东",
            41: "河南",
            42: "湖北 ",
            43: "湖南",
            44: "广东",
            45: "广西",
            46: "海南",
            50: "重庆",
            51: "四川",
            52: "贵州",
            53: "云南",
            54: "西藏 ",
            61: "陕西",
            62: "甘肃",
            63: "青海",
            64: "宁夏",
            65: "新疆",
            71: "台湾",
            81: "香港",
            82: "澳门",
            91: "国外 "
        };
        let tip = null;
        //验证身份证格式（6个地区编码，8位出生日期，3位顺序号，1位校验位）
        if (!code || !/^\d{6}(18|19|20)?\d{2}(0[1-9]|1[012])(0[1-9]|[12]\d|3[01])\d{3}(\d|X)$/i.test(code)) {
            tip = "身份证号格式错误";
        } else if (!city[code.substr(0, 2)]) {
            tip = "地址编码错误";
        } else {
            //18位身份证需要验证最后一位校验位
            if (code.length == 18) {
                code = code.split('');
                //∑(ai×Wi)(mod 11)
                //加权因子
                let factor = [7, 9, 10, 5, 8, 4, 2, 1, 6, 3, 7, 9, 10, 5, 8, 4, 2];
                //校验位
                let parity = [1, 0, 'X', 9, 8, 7, 6, 5, 4, 3, 2];
                let sum = 0;
                let ai = 0;
                let wi = 0;
                for (let i = 0; i < 17; i++) {
                    ai = code[i];
                    wi = factor[i];
                    sum += ai * wi;
                }
                let last = parity[sum % 11];
                if (parity[sum % 11] != code[17]) {
                    tip = "校验位错误";
                }
            }
        }

        if (tip) return false;
        return true;
    }

    static isEmail (s) {
        return /^[a-zA-Z0-9_-]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/.test(s);
    }

    static findFullnameForArea (provinceId, cityId, countyId) {
        let fullname = '';
        let exit = false;
        for (let i = 0; i < AreaList.length; i++) {
            let province = AreaList[i];
            if (province.c == provinceId) {
                fullname += province.n;

                if (cityId) {
                    for (let j = 0; j < province.s.length; j++) {
                        let city = province.s[j];
                        if (city.c == cityId) {
                            fullname += city.n;

                            if (countyId) {
                                for (let k = 0; k < city.s.length; k++) {
                                    let county = city.s[k];
                                    if (county.c == countyId) {
                                        fullname += county.n;
                                        break;
                                    }
                                }
                            }

                            break;
                        }
                    }
                }

                exit = true;
            }
            if (exit) {
                break;
            }
        }
        return fullname;
    }

    /***
     * 数组去重
     *
     * @param arr
     * @returns {any[]}
     */
    static uniqueArray (arr) {
        return Array.from(new Set(arr));
    }

    /***
     * 转换键值对为选项数据
     * @param str
     */
    static convertKVToDict (kvStr) {
        let arr = convertStrToArray(kvStr);
        const dict = [];
        arr.forEach(item => {
            const temp = item.split(':');
            const dictItem = {};
            dictItem.value = temp[0];
            dictItem.label = temp[1];
            dict.push(dictItem)
        });
        return dict
    }

    /***
     * 压缩字符串
     * @param records
     */
    static gzip (records) {
        const jsonStrData = JSON.stringify(records)
        // console.log('---jsonStrData---',jsonStrData)
        const zipArray = pako.gzip(encodeURIComponent(jsonStrData))
        let outputStr = ''
        for (let i = 0; i < zipArray.length; i++) {
            let num = zipArray[i]
            if (~[13, 34, 39, 44, 60, 62, 92, 96, 10, 0].indexOf(num)) {
                num += 300
            }
            outputStr += String.fromCharCode(num)
        }
        return outputStr
    }

    static validateDate (str) {
        if (!str) {
            return false;
        }
        let trimed_str = str.replace(/-/g, '');
        trimed_str = trimed_str.replace(/ /g, '');
        if (trimed_str.length != 8) {
            return false;
        }
        trimed_str = trimed_str.substr(0, 4) + '-' + trimed_str.substr(4, 2) + '-' + trimed_str.substr(6, 2);
        // return moment(trimed_str, true).isValid();
        return BTUtil.validateDate2(trimed_str);
    }

    static formattedDate (str) {
        let trimed_str = str.replace(/-/g, '');
        trimed_str = trimed_str.replace(/ /g, '');
        trimed_str = trimed_str.substr(0, 4) + '-' + trimed_str.substr(4, 2) + '-' + trimed_str.substr(6, 2);
        return trimed_str;
    }

    static contentLen (str) {
        let len = 0;
        for (let i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
                len += 2;
            } else {
                len++;
            }
        }
        return len;
    }

    static getRandomStr (length = 8) {
        //return Math.random().toString(36).slice(-8);
        const chars = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
        var result = '';
        for (var i = length; i > 0; --i) result += chars[Math.floor(Math.random() * chars.length)];
        return result;
    }

    static validateDate2 (strInputDate) {
        let DA = [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
        let strDate = strInputDate.replace(/-/g, "/");

        if (strDate.indexOf("/") == -1) {
            return false;
        }

        let arrD = strDate.split("/");

        if (arrD.length != 3) return false;

        let y = parseInt(arrD[0], 10);
        let m = parseInt(arrD[1], 10);
        let d = parseInt(arrD[2], 10);


        if (isNaN(y) || isNaN(m) || isNaN(d)) return false;
        if (m > 12 || m < 1) return false;


        if (BTUtil.isLoopYear(y)) DA[2] = 29;

        if (d > DA[m]) return false;

        let str = y + "-" + (m < 10 ? "0" : "") + m + "-" + (d < 10 ? "0" : "") + d;
        return true;
    }

    static isLoopYear (theYear) {
        return (new Date(theYear, 1, 29).getDate() == 29);
    }

    static contentCharLen (str) {
        let len = 0;
        for (let i = 0; i < str.length; i++) {
            if (str.charCodeAt(i) > 127 || str.charCodeAt(i) == 94) {
                len += 2;
            } else {
                len++;
            }
        }
        return len;
    }

    static isIE () {
        if (navigator.appName === 'Microsoft Internet Explorer') {
            return true
        } else if (navigator.appName === 'Netscape' && /Trident/.test(navigator.userAgent)) { // IE 11
            return true
        }
        return false
    };


    // 格式化货币
    static formatMoney (num) {
        if (num) {
            if (isNaN(num)) {
                return '0.00';
            }
            num = typeof num == "string" ? parseFloat(num) : num
            num = num.toFixed(2);
            num = parseFloat(num);
            num = num.toLocaleString();
            //判断是否有小数
            if (num.indexOf(".") == -1) {
                //num = "￥" + num + ".00";
                num = num + ".00";
            } else {
                //num = num.split(".")[1].length < 2 ? "￥" + num + "0" : "￥" + num;
                num = num.split(".")[1].length < 2 ? num + "0" : num;
            }
            return num;
        } else {
            return num = 0;
        }
    }

    //去除尾部0的金额显示
    static formatMoneyNoZero (num, len = 2) {
        if (num) {
            if (isNaN(num)) {
                return 0;
            }
            num = typeof num == "string" ? parseFloat(num) : num
            num = num.toFixed(len);
            let str = num;
            let result = str;
            for (let i = str.length - 1; i > 0; i--) {
                if (str[i] == '.') {
                    result = result.slice(0, result.length - 1);
                    break;
                }

                if (str[i] != '0') break;

                result = result.slice(0, result.length - 1);
            }

            return result;
        }

        return num;
    }

    //元改成万元
    static formatMoneyShort (amount) {
        if (amount >= 10000) {
            return formatMoneyNoZero(amount / 10000) + '万';
        } else {
            amount = parseFloat(amount);
            amount = amount.toLocaleString();
            //判断是否有小数
            if (amount.indexOf(".") == -1) {
                amount = amount + ".00";
            } else {
                //num = num.split(".")[1].length < 2 ? "￥" + num + "0" : "￥" + num;
                amount = amount.split(".")[1].length < 2 ? amount + "0" : amount;
            }
        }
        return amount;
    }

    /**分转元
     *
     * @param amountFen
     * @returns {*}
     */
    static formatMoneyYuan (amountFen) {
        if (typeof amountFen !== "number" || isNaN(amountFen)) return null;
        return (amountFen / 100).toFixed(2);
    }

    // 限制字数
    static ellipsis(value, maxLength) {
        if (!value) return ''
        value = filterHtml(value);
        // 去掉空格
        value = value.replaceAll("&nbsp;",'')
        if (value.length > maxLength) {
            return value.slice(0, maxLength) + '...'
        }
        return value
    }
}
