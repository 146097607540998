export * from './format';
import { BTUtil } from "@/core/common/util"

// 限制字数
export function ellipsis (value, maxLength) {
    if (!value) return ''
    value = filterHtml(value);
    if (value.length > maxLength) {
        return value.slice(0, maxLength) + '...'
    }
    return value
}

/**
 * 隐藏手机号中间四位
 * @param cellValue
 * @returns {*}
 */
export function hidePhoneNum (cellValue) {
    if (Number(cellValue) && String(cellValue).length === 11) {
        var mobile = String(cellValue)
        var reg = /^(\d{3})\d{4}(\d{4})$/
        return mobile.replace(reg, '$1****$2')
    } else {
        return cellValue
    }
}

/***转换不同尺寸图片 small 160*160 middle  320宽  large 640宽
 *
 * @param picUrl
 * @param size
 * @returns {*|*}
 */
export function convertPicUrl (picUrl, size = 'middle') {
    return BTUtil.convertPicUrl(picUrl, size);
}

/***在线预览文档
 *
 * @param docUrl
 * @param type
 * @returns {*}
 */
export function convertDocUrl (docTitle, docUrl, type = 'pdf') {
    return BTUtil.convertDocUrl(docTitle, docUrl, type);
}


// 过滤html
export function filterHtml (value) {
    return value.replace(/<[^>]*>/g, '');
}


export function showPicUrl (picUrl, size) {
    return BTUtil.showPicUrl(picUrl, size);
}

export function getPicUrl (picUrl, size) {
    return BTUtil.getPicUrl(picUrl, size);
}

export function filterProtocol (picUrl) {
    return BTUtil.filterProtocol(picUrl);
}


