function loadView (view) {
    return () => import(`./${view}.vue`)
}

export default [
    {
        path: '/home',
        name: 'home',
        component: () => import('../view/index'),
        meta: {
            title: '链保科技',
            auth: false,
            keepAlive: true,
            fullScreen: false,
        },
    },

    /**************  行业咨询 start **************/
    {
        path: '/advert/list',
        name: 'advertIndex',
        component: () => import('../view/advert/index'),
        meta: {
            title: '行业资讯',
            keepAlive: true,
            auth: false,
            fullScreen: true,
        }
    },
    {
        path: '/advert/info',
        name: 'advertInfo',
        component: () => import('../view/advert/advert_info'),
        meta: {
            title: '资讯详情',
            keepAlive: true,
            auth: false,
            fullScreen: true,
        }
    },
    /**************  行业咨询 end **************/

]
