<template>
    <div id="app">
        <keep-alive>
            <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>

        <div class="footer" v-show="!$route.meta.hideFooter">
            <div class="copyright" style="padding-bottom: 5px;">-提供一站式货运保险服务-</div>
            <div class="copyright" style="padding-top: 5px;">©&nbsp;2023-2024&nbsp;链保科技</div>
        </div>

        <LbTab v-if="!$route.meta.fullScreen"/>

    </div>
</template>

<script>
    import LbTab from '@/core/component/LbTab/LbTab'

    export default {
        components: {
            LbTab,
        },
    }
</script>

<style>
    html {
        height: 100%;
        width: 100%;
    }

    body {
        height: 100%;
        width: 100%;
        font-family: 'Helvetica Neue', Helvetica, Arial, 'PingFang SC', 'Hiragino Sans GB', 'Heiti SC', sans-serif;
        -webkit-font-smoothing: antialiased;
    }

    .footer {
        text-align: center;
        font-size: 12px;
        padding: 1em 0;
        color: #969696;
        clear: both;
        padding-bottom: 70px;
    }

    .copyright {
        padding: 15px;
        text-align: center;
        font-size: 14px;
        color: #6d6d6d;
    }
</style>
