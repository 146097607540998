import moment from 'moment';
import _ from 'lodash'
import { BTUtil } from "@/core/common/util"

// 格式化货币
export function formatMoney (num) {
    return BTUtil.formatMoney(num)
}

//去除尾部0的金额显示
export function formatMoneyNoZero (num, len = 2) {
    return BTUtil.formatMoneyNoZero(num, len)
}

//元改成万元
export function formatMoneyShort (amount) {
    return BTUtil.formatMoneyShort(amount)
}

/**分转元
 *
 * @param amountFen
 * @returns {*}
 */
export function formatMoneyYuan (amountFen) {
    return BTUtil.formatMoneyYuan(amountFen)
}

/**
 * 数字格式化
 *
 * @export
 * @param {any} number 要格式化的数字
 * @param {any} decimals 保留几位小数
 * @param {any} decPoint 小数点符号
 * @param {any} thousandsSep 千分位符号
 * @returns
 */
export function formatNumber (number, decimals, decPoint, thousandsSep) {
    number = (number + '').replace(/[^0-9+-Ee.]/g, '');
    let n = !isFinite(+number) ? 0 : +number;
    let prec = !isFinite(+decimals) ? 0 : Math.abs(decimals);
    let sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep;
    let dec = typeof decPoint === 'undefined' ? '.' : decPoint;
    let s = '';
    let toFixedFix = function (n, prec) {
        let k = Math.pow(10, prec);
        return '' + Math.ceil(n * k) / k;
    };

    s = (prec ? toFixedFix(n, prec) : '' + Math.round(n)).split('.');
    let re = /(-?\d+)(\d{3})/;
    while (re.test(s[0])) {
        s[0] = s[0].replace(re, '$1' + sep + '$2');
    }

    if ((s[1] || '').length < prec) {
        s[1] = s[1] || '';
        s[1] += new Array(prec - s[1].length + 1).join('0');
    }
    return s.join(dec);
}

/***格式化比例 */
export function formatRate (val, count = 0) {
    return val.toFixed(count) + '%'
}

/**百分比安全范围*/
export function formatPercent (val) {
    return Math.min(Math.max(val, 0), 100);
}

/**
 * 格式化时间
 * @param time
 * @param format
 * @returns {*}
 */
export function formatTime (time, format) {
    if (!time) return ''
    format = format != null ? format : 'YYYY-MM-DD HH:mm'
    return moment(time).format(format)
}

export function formatDate (time, format) {
    if (!time) return ''
    format = format != null ? format : 'YYYY-MM-DD'
    return moment(time).format(format)
}

/**
 * 时间格式化
 * @param time
 * @returns {*}
 */
export function formatTimeAgo (time) {
    let times = moment(time).format('x') / 1000
    const between = Date.now() / 1000 - Number(times)
    if (between < 60) {
        return pluralize('', '刚刚')
    } else if (between < 3600) {
        return pluralize(~~(between / 60), '分钟前')
    } else if (between < 86400) {
        return pluralize(~~(between / 3600), '小时前')
    } else {
        return pluralize(~~(between / 86400), '天前')
    }
}

/**
 * 秒转可读时间
 * @param time
 * @returns {*}
 */
export function formatTimeCount (second) {
    if (second < 60) {
        return second + '秒'
    } else if (second >= 60 && second < 3600) {
        return Math.floor(second / 60) + '分钟';
    } else if (second >= 3600 && second < 86400) {
        return Math.floor(second / 3600) + '小时';
    } else if (second >= 86400 && second < 86400 * 30) {
        return Math.floor(second / 86400) + '天';
    } else {
        return '-'
    }
}

/**
 * 隐藏姓名 手机号 身份证 银行卡 等中间字符信息
 * @param str
 * @returns {*}
 */
export function formatReal (value) {
    let str = value;
    if (!_.isEmpty(value)) {
        if (str.length > 0 && str.length < 3) {
            return str.substring(0, 1) + '*'
        } else if (str.length == 3) {
            return str.substr(0, 1) + '*' + str.substr(-1)
        } else if (str.length == 4) {
            return str.substr(0, 1) + '**' + str.substr(-1)
        } else if (str.length > 5 && str.length < 12) {
            return str.substr(0, 3) + '****' + str.substr(-4)
        } else if (str.length > 14) {
            return str.substr(0, 4) + '***********' + str.substr(-4)
        } else {
            return str
        }
    }
    return str;
}


