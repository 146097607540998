import BTCache from "@/core/common/cache"

const prefix = 'product_';
const product = {
    namespaced: true,
    state: {
        product_detail: null,
    },
    getters: {
        product_detail: state => {
            if (!state.product_detail) {
                let cached_product = BTCache.getCache(prefix + 'product_detail');
                if (cached_product) state.product_detail = JSON.parse(cached_product);
            }
            return state.product_detail;
        },
    },

    mutations: {
        set_product_detail: (state, product_detail) => {
            state.product_detail = product_detail;
            if (!product_detail) {
                BTCache.removeCache(prefix + 'product_detail');
            } else {
                BTCache.setCache(prefix + 'product_detail', JSON.stringify(product_detail));
            }
        },

    },

    actions: {}
};

export default product;
