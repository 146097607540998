import BTHttp from '@/core/common/request'

export class ProductApi {

    requestApi (api, data, method = 'post', meta, type = 'json') {


        return BTHttp.request(api, data, method, meta, type)
    }

}

export function productIssuePage (obj) {
    return BTHttp({
        url: `/product/wx/productIssuePage`,
        method: 'post',
        data: obj
    })
}

export function productDetail (productCode) {
    return BTHttp({
        url: `/product/wx/productDetail/`+productCode,
        method: 'get'
    })
}
