import request from '@/core/common/request'
import BTCache from '@/core/common/cache'

export class UserApi {

    requestApi (method, data) {
        const requestData = {
            "method": method,
            "data": data,
            "version": "1.0",
            "from": "h5",
            "token": BTCache.getToken()
        }
        return request({
            url: '/v1',
            method: 'post',
            data: requestData
        })
    }

    sync_wxLogin (loginForm) {
        return this.requestApi('user.wx.login', loginForm);
    }

    sync_loginOut (loginForm) {
        return this.requestApi('user.wx.loginOut', loginForm);
    }

    login (form) {
        return this.requestApi('user.login', form);
    }

    userInfo () {
        return this.requestApi('user.info', {});
    }
}

const path = '/sys';
// 登录
export function login (data) {
    return request({
        url: `${path}/wxLogin`,
        method: 'post',
        data: data
    })
}

// 登录
export function loginByPhone (data) {
    return request({
        url: `${path}/wxLoginByPhone`,
        method: 'post',
        data: data
    })
}


// 退出
export function logout () {
    return request({
        url: `${path}/logout`,
        method: 'post'
    })
}

// 获取用户信息
export function getUserInfo () {
    return request({
        url: `${path}/user/`,
        method: 'get',
        params: {}
    })
}

// 获取用户结算信息
export function userCommissionAccount () {
    return request({
        url: `/fund/account/userCommissionAccount/`,
        method: 'get',
        params: {}
    })
}


/***
 *  我的资金账户
 */
export function myAccount () {
    return request({
        url: `/fund/account/my`,
        method: 'get'
    })
}

/***
 *  用户发送验证码
 */
export function sendVerifyCode (data) {
    return request({
        url: `${path}/sendVerifyCode`,
        method: 'post',
        data: data
    })
}

/***
 *  校验验证码
 */
export function checkVerifyCode (data) {
    return request({
        url: `${path}/checkVerifyCode`,
        method: 'post',
        data: data
    })
}
