export default [
    {
        path: '/orderList',
        name: 'orderList',
        component: () => import('../view/index'),
        meta: {
            title: '订单列表',
            keepAlive: true,
            auth: true,
            fullScreen: true,
        }
    },
    {
        path: '/orderDetail',
        name: 'orderDetail',
        component: () => import('../view/orderDetail'),
        meta: {
            title: '订单列表',
            keepAlive: true,
            auth: true,
            fullScreen: true,
        }
    },
]

