import Vue from 'vue'
import Vuex from 'vuex'
import user from '@/modules/user/core/store'
import product from '@/modules/product/core/store'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        user,
        product
    }
})
