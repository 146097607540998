import Vue from 'vue'
import VueRouter from 'vue-router'
import BTCache from "@/core/common/cache"
import { BTUtil } from "@/core/common/util"
/* Router Modules */
import homeRouter from '@/modules/home/core/router'
import productRouter from '@/modules/product/core/router'
import userRouter from '@/modules/user/core/router'
import orderRouter from '@/modules/order/core/router'

Vue.use(VueRouter)

const routes = [
    ...homeRouter,
    ...productRouter,
    ...userRouter,
    ...orderRouter
];

const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
    return routerPush.call(this, location).catch((error) => error);
};

//跳转至登录页
function goLogin (to, next) {
    const redirect = encodeURIComponent(to.fullPath)
    next(`/login?redirect=${redirect}`);
}

routes.push({path: '/', redirect: '/home'});
// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});
// routes.push({path: '*', redirect: '/404'});

const router = new VueRouter({
    mode: 'history',
    routes,
    scrollBehavior (to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {x: 0, y: 0}
        }
    }
})

router.beforeEach((to, form, next) => {
    if (to.meta.background) {
        document.querySelector('body').setAttribute('style', 'background-color: ' + to.meta.background);
    } else {
        document.querySelector('body').setAttribute('style', 'background-image:none;background-color: #f5f5f5');
    }

    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }

    if (to.query.token) {
        BTCache.setToken(to.query.token);
        delete to.query.token;
    }

    if (!to.meta.auth) {
        next();
    } else {
        const token = BTCache.getToken()
        if (!token) {
            goLogin(to, next);
        }
        next();
    }
})

export default router
