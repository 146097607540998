import { BTHttp } from "@/core/common/request";

//定位key
const LBS_KEY = "TX2BZ-5N734-HIUUP-DNROT-WCLZE-GDBOU";

const wxApiUrl = process.env.VUE_APP_WX_API_URL + "/" + process.env.VUE_APP_WECHAT_APPID;

export class WxApi {
    getUserWxInfo (params) {
        return BTHttp.request(`${wxApiUrl}/auth/userinfo`, params, 'get');
    }

    //微信jssdk
    wx_js_signature (url) {
        return BTHttp.request(`${wxApiUrl}/jssdk/signature`, {url: url}, 'get');
    }
}
